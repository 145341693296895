<template>
  <div>
    <main id="main">
      <section class="section_outer section_start">
        <div class="section_inner">
          <section-record
            :priceList="priceList"
            :main_phone_number="main_phone_number"
            :servicesList="servicesList"
          ></section-record>
        </div>
      </section>
      <section class="section_outer section_substart">
        <promo></promo>
      </section>
      <section class="section_outer section_cinfo">
        <div class="section_inner">
          <text-index></text-index>
        </div>
      </section>
      <section class="section_outer section_free">
        <div class="section_inner">
          <form-phone></form-phone>
        </div>
      </section>
      <section class="section_outer section_cost">
        <div class="section_inner">
          <div class="section_wrapper">
            <div class="cost_left">
              <h3>Прейскурант ООО МДЦ «ПРОЗДОРОВЬЕ»</h3>
              <p>
                Для вашего удобства мы собрали все услуги в едином документе с
                актуальными наименованиями и ценами.
              </p>
            </div>
            <a
              :href="priceList?.main_price_list"
              style="text-decoration: none !important;"
              class="cost_right"
              >Стоимость услуг</a
            >
          </div>
        </div>
      </section>
      <section class="section_outer section_service">
        <div class="section_inner-big">
          <service></service>
        </div>
      </section>
      <section class="section_outer section_specialists">
        <div class="section_inner">
          <section_doctor :servicesList="servicesList"></section_doctor>
        </div>
      </section>
      <section class="section_outer section_step">
        <div class="section_inner">
          <section_step :main_phone_number="main_phone_number"></section_step>
        </div>
      </section>
      <section class="section_outer section_advantage">
        <div class="section_inner">
          <section_advantages></section_advantages>
        </div>
      </section>
      <section class="section_outer section_question">
        <div class="section_inner">
          <form_email :questions_remain="questions_remain"></form_email>
        </div>
      </section>
      <section class="section_outer section_map" :class="{'lock': stateMap }" @click="unlockMap">
        <iframe
          src="https://yandex.ru/map-widget/v1/?um=constructor%3A4b777ad9d60b9ad90c0c1ce7a12dad91a3b608698687bde0dfabcbb8574fc8f8&amp;source=constructor"
          width="100%"
          height="601"
          frameborder="0"
        ></iframe>
      </section>
    </main>
  </div>
</template>

<script src="./index.js"></script>

<style lang="scss">

.lock {
  iframe {
    pointer-events: none;
  }
}
</style>
