<template>
  <div
    class="start_specialty_date date"
    @click="showSelect"
    @blur="hideSelect"
    tabindex="0"
  >
    <div
      class="start_specialty__title"
      :class="{ selected: value, disabled: disabled }"
    >
      <input
        :class="{ animate: value }"
        disabled
        :value="value ? value : placeholder"
        class="current_value"
        type="text"
      />
      <div class="btn-if">
        <transition name="mode-fade" mode="out-in">
          <button
            v-if="!value"
            @click.stop="showSelect"
            key="open"
            class="select_open"
          >
            <svg
              width="14"
              height="8"
              viewBox="0 0 14 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M6.98805 7.98682L7.00069 8L14 0.695993L13.3334 0.00034851L7.00037 6.60904L0.666621 -0.000436995L-3.17609e-06 0.695208L6.98773 7.98715L6.98805 7.98682Z"
              />
            </svg>
          </button>
          <button
            v-else
            @click.stop="resetValue"
            key="close"
            class="select_reset"
          >
            <svg
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M0 0.666708L0.666647 4.38888e-05L13.9996 13.3333L13.3329 14L0 0.666708Z"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M13.3334 0L14 0.666664L0.667064 14L0.000417038 13.3333L13.3334 0Z"
              />
            </svg>
          </button>
        </transition>
      </div>
    </div>
    <transition name="bounce">
      <div class="start_specialty__list-wrapper" v-if="isActive">
        <ul
          class="start_specialty__list"
          style="display: grid; grid-template-columns: 1fr 1fr 1fr; width: 100%;"
        >
          <li
            @click.stop="setValue(item)"
            v-for="(item, index) in elementList"
            :id="index"
            :key="index"
            style="white-space: nowrap; "
            :class="{closed: item?.is_free === false}"
          >
            {{ funcDataVision(item) }}
          </li>
        </ul>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: "DefaultSelectModal",
  components: {},
  data() {
    return {
      isActive: false,
    };
  },
  props: [
    "value",
    "funcDataVision",
    "elementList",
    "disabled",
    "placeholder",
    "valueName",
  ],
  methods: {
    hideSelect() {
      this.isActive = false;
    },
    resetValue() {
      this.hideSelect();
      this.$emit(this.valueName, this.valueName, "");
    },
    setValue(item) {
      if (item?.is_free === false) return
      this.$emit(this.valueName, this.valueName, item);
      this.hideSelect();
    },
    showSelect() {
      if (this.disabled) return;
      this.isActive = !this.isActive;
    },
  },
};
</script>
<style lang="scss" scoped>
.start_specialty_date .start_specialty__title.disabled:hover {
  background-color: rgb(252, 201, 201);
  color: #fff;
}
.start_specialty_date .start_specialty__title {
  transition: 0.35s all ease;
  border: 1px solid #ededed;
}
.start_specialty_date.error .start_specialty__title {
  transition: 0.35s all ease;
  border: 1px solid rgb(255, 149, 149);
}
</style>