<template>
  <div class="section_wrapper">
    <h1>ООО Медицинский Диагностический Центр «ПРОЗДОРОВЬЕ»</h1>
    <p>
      Клиника «ПРОЗДОРОВЬЕ» имеют лицензию на все заявленные виды доврачебной и
      амбулаторно-поликлинической помощи. Подробнее с копией лицензии вы можете
      ознакомиться в разделе «Сертификаты и лицензии».
    </p>
    <p>
      Врачи клиники «ПРОЗДОРОВЬЕ» ведут прием пациентов по продолжительности,
      который необходим для сбора максимально подробного анамнеза, внимательного
      осмотра пациента и проведения диагностики. Вы можете быть уверены, что для
      решения вашей проблемы доктор уделит именно столько внимания, сколько
      потребуется – ведь речь идет о вашем здоровье! Коллектив врачей клиники
      «ПРОЗДОРОВЬЕ» составляют кандидаты наук, специалисты высшей и первой
      категории, настоящие профессионалы своего дела с большим опытом работы,
      прошедшие необходимые стажировки и дополнительное обучение. При обращении
      в клинику обязательно заключается договор на оказание медицинских услуг.
      Администраторы клиники «ПРОЗДОРОВЬЕ» окажут вам всю необходимую помощь при
      ознакомлении с договором на оказание медицинских услуг, а также разъяснят
      все непонятные положения и предоставят всю информацию, которая вас
      заинтересует.
    </p>
    <p class="bold">
      Команда высококвалифицированных специалистов, широкий спектр медицинских
      услуг и высокий уровень сервиса – главные преимущества нашей клиники!
    </p>
  </div>
</template>

<script>
export default {
  name: "text_index",
};
</script>

<style scoped></style>
