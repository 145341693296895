import { createApp } from 'vue'
import app from './app/app.vue'
import store from './store'
import router from './router'
import Maska from 'maska'
import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";
import "swiper/components/pagination/pagination.scss";
createApp(app).use(Maska).use(store).use(router).mount('#app')

let vh = window.innerHeight * 0.01;
document.documentElement.style.setProperty('--vh', `${vh}px`);