<template>
  <main class="laboratory__main" id="main">
    <section class="section_outer section_bread_crumbs">
      <div class="section_inner">
        <div class="section_wrapper">
          <div class="bread_crumbs__main">
            <router-link to="/">Медицинская клиника «ПРОЗДОРОВЬЕ»</router-link>
          </div>
          <div class="bread_crumbs__separator">/</div>
          <div class="bread_crumbs__main">
            <router-link to="/services">Услуги</router-link>
          </div>
          <div class="bread_crumbs__separator">/</div>
          <div class="bread_crumbs__next">Лабораторные исследования</div>
        </div>
      </div>
    </section>
    <section class="section_outer section_service_item">
      <div class="section_inner">
        <div class="section_wrapper">
          <div class="section__title">Лабораторные исследования</div>
          <div class="service_item__wrapper">
            <img class="service_item__image" src="../../../../public/img/laboratory.png" alt="" />
            <div class="service_item__info">
              <div class="service_item__title">
                Клиническая лабораторная диагностика
              </div>
              <div class="service_item__description">
                На современном этапе невозможно представить современную медицину без клинической лабораторной диагностики. С её помощью на ранней стадии выявляются патологии, осуществляется мониторинг развития заболевания, производится оценка его возможного течения и определяется эффективность назначенного лечения. Своевременное и быстрое проведение различных анализов необходимо для правильной постановки диагноза. Основную часть информации о состоянии здоровья пациента врач получает на основании диагностических исследований, в том числе анализов. Только имея результаты анализов можно точно определить заболевание и назначить эффективное лечение.
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="section_outer section_service_cost">
      <div class="section_inner">
        <div class="section_wrapper">
          <div class="service_cost__left">
            <div class="service_cost__title">
              Лабораторные исследования — Прейскурант <br> ООО МДЦ «ПРОЗДОРОВЬЕ»
            </div>
            <div class="service_cost__bottom">
              <p>Мы рады помочь Вам по всем вопросам — звоните!</p>
              <a :href="`tel:${main_phone_number}`">
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M15.5432 11.3061L12.0431 9.80686C11.8937 9.7431 11.7276 9.72963 11.5699 9.76848C11.4121 9.80733 11.2712 9.8964 11.1683 10.0223L9.61825 11.9159C7.18686 10.7701 5.2292 8.8122 4.08115 6.37813L5.97485 4.82824C6.10104 4.72579 6.19039 4.58486 6.22932 4.42687C6.26825 4.26888 6.25463 4.10247 6.19052 3.95294L4.69041 0.453035C4.61991 0.291798 4.49551 0.160233 4.33863 0.0809949C4.18175 0.00175651 4.00221 -0.0201974 3.83092 0.0189132L0.581101 0.768527C0.415991 0.806539 0.268626 0.899529 0.163079 1.03231C0.0575318 1.16508 4.16567e-05 1.3298 0 1.49955C0.00159593 3.4057 0.377944 5.29286 1.10755 7.05325C1.83715 8.81364 2.90572 10.4128 4.25222 11.7593C5.59872 13.1059 7.19678 14.1734 8.95512 14.9011C10.7135 15.6287 12.5976 16.0021 14.5 16C14.6695 16.0003 14.8342 15.9431 14.9671 15.8376C15.0999 15.732 15.1931 15.5845 15.2315 15.419L15.9816 12.1692C16.0202 11.9969 15.9977 11.8164 15.9177 11.659C15.8378 11.5015 15.7056 11.3769 15.5438 11.3067L15.5432 11.3061Z"
                    fill="#0DC2B6"
                  />
                </svg>
                {{ main_phone_number }}
              </a>
            </div>
          </div>
          <a :href="priceList?.laboratory_research_price_list" class="cost_right" target="_black"
            >Стоимость услуг</a
          >
        </div>
      </div>
    </section>
    <section class="section_outer section_service_text">
      <div class="section_inner">
        <div class="section_wrapper">
          <div class="section__title">
            Лабораторные исследования ООО МДЦ «ПРОЗДОРОВЬЕ»
          </div>
          <ol class="service_text__number_list">
            <li class="service_text__number_item">
              <p class="service_text__number_title">
                В нашей клинике можно сдать весь спектр медицинских анализов, в
                том числе:
              </p>
              <ul>
                <li class="service_text__number_paragraf">
                  Общеклинические анализы крови, мочи, кала.
                </li>
                <li class="service_text__number_paragraf">
                  Биохимические анализы крови, мочи, кала.
                </li>
                <li class="service_text__number_paragraf">
                  Микробиологические исследования.
                </li>
                <li class="service_text__number_paragraf">
                  Гормональные исследования.
                </li>
                <li class="service_text__number_paragraf">
                  Генетические анализы.
                </li>
                <li class="service_text__number_paragraf">
                  Анализы для диагностики аутоиммунных заболеваний.
                </li>
                <li class="service_text__number_paragraf">
                  Аллергологические анализы.
                </li>
                <li class="service_text__number_paragraf">
                  Иммунологические, иммуногематологические,
                  иммуногистохимические анализы.
                </li>
                <li class="service_text__number_paragraf">
                  Лекарственный мониторинг.
                </li>
                <li class="service_text__number_paragraf">
                  Анализ на содержание микроэлементов.
                </li>
                <li class="service_text__number_paragraf">
                  Исследование мазков у мужчин и женщин.
                </li>
                <li class="service_text__number_paragraf">
                  Выявление психоактивных и наркотических веществ.
                </li>
                <li class="service_text__number_paragraf">
                  Цитологические и гистологические исследования.
                </li>
                <li class="service_text__number_paragraf">
                  Анализы на онкомаркеры.
                </li>
                <li class="service_text__number_paragraf">
                  Исследования образцов кожи и ногтевых пластинок.
                </li>
              </ul>
              <p class="service_text__number_paragraf margin">
                По мнению многих специалистов, регулярно проводя общие
                исследования крови и мочи, можно своевременно диагностировать
                многие воспалительные процессы, а сведения о состоянии организма
                и склонности к различным заболеваниям, позволят провести
                профилактику и предотвратить их развитие.
              </p>
              <p class="service_text__number_paragraf">
                В медицинском центре «ПРОЗДОРОВЬЕ» проводятся лабораторные
                исследования быстро, безболезненно и в удобное время.
                Результаты исследований в самые короткие сроки поступают сразу
                лечащему врачу. Пациент может выбрать наиболее удобный для себя
                способ получения результатов исследований: электронная почта или
                лично в руки.
              </p>
              <p class="service_text__number_paragraf margin">
                Клиника «ПРОЗДОРОВЬЕ» сотрудничает с ведущими лабораториями
                Российской Федерации, что обеспечивает высокую достоверность
                исследований и скорость выполнения.
              </p>
            </li>
          </ol>
        </div>
      </div>
    </section>
    <section class="section_outer section_service_med">
      <div class="section_inner">
        <div class="section_wrapper">
          <div class="section__title">
            Специалисты лабораторной диагностики ООО МДЦ «ПРОЗДОРОВЬЕ»
          </div>
          <ul class="service_med__list">
            <li
              class="service_med__item"
              :key="index"
              v-for="(doctor, index) in dataService?.doctors.filter(item=>item.is_shown)"
            >
              <img :src="doctor.photo" alt="" />
              <div class="service_med__item_name" v-html="`${doctor.surname} ${doctor.name} ${doctor.patronymic}`">
              </div>
              <div class="service_med__item_work" v-html="doctor.position">
              </div>
              <div class="service_med__item_date">
                {{ doctor.experience }}
              </div>
              <button
                class="service_med__item_button"
                @click="doctorOpenModal(doctor)"
              >
                Записаться
              </button>
            </li>
          </ul>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
export default {
  name: "laboratory",
  props: ["main_phone_number", "priceList", "servicesList"],
  data() {
    return {
      title: "Лабораторные исследования",
    };
  },
  computed: {
    dataService() {
      return this.servicesList
        ? this.servicesList.find((item) => item.name === this.title)
        : null;
    },
  },
  methods: {
    openRecordModal() {
      this.$store.commit("updateRecordModalValues", {
        keyModal: "selectedCategory",
        valueModal: this.dataService,
      });
      this.$store.commit("toggleRecordModalState", "recordModal");
    },
    doctorOpenModal(doctor) {
      this.$store.commit("toggleRecordModalState", "recordModal");
      this.$store.commit("updateRecordModalValues", {
        keyModal: "selectedCategory",
        valueModal: this.dataService,
      });
      this.$store.commit("updateRecordModalValues", {
        keyModal: "selectedDoctor",
        valueModal: doctor,
      });
    },
  },
};
</script>

<style scoped></style>
