<template>
  <div class="section_wrapper">
    <ul class="advantage_list">
      <li class="advantage_item">
        <div class="advantage_item__circle">
          <svg
            width="67"
            height="64"
            viewBox="0 0 67 64"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M60.7188 12.8H50.25V6.4C50.25 2.86667 47.4365 0 43.9688 0H23.0312C19.5635 0 16.75 2.86667 16.75 6.4V12.8H6.28125C2.81348 12.8 0 15.6667 0 19.2V57.6C0 61.1333 2.81348 64 6.28125 64H60.7188C64.1865 64 67 61.1333 67 57.6V19.2C67 15.6667 64.1865 12.8 60.7188 12.8ZM25.125 8.53333H41.875V12.8H25.125V8.53333ZM46.0625 41.6C46.0625 42.1867 45.5914 42.6667 45.0156 42.6667H37.6875V50.1333C37.6875 50.72 37.2164 51.2 36.6406 51.2H30.3594C29.7836 51.2 29.3125 50.72 29.3125 50.1333V42.6667H21.9844C21.4086 42.6667 20.9375 42.1867 20.9375 41.6V35.2C20.9375 34.6133 21.4086 34.1333 21.9844 34.1333H29.3125V26.6667C29.3125 26.08 29.7836 25.6 30.3594 25.6H36.6406C37.2164 25.6 37.6875 26.08 37.6875 26.6667V34.1333H45.0156C45.5914 34.1333 46.0625 34.6133 46.0625 35.2V41.6Z"
              fill="white"
            />
          </svg>
        </div>
        <div class="advantage_item__title">
          Высококвалифицированные специалисты
        </div>
        <p class="advantage_item__text">
          Гордость медицинского центра
          «ПРОЗДОРОВЬЕ» профессиональный коллектив специалистов различного
          профиля.
        </p>
      </li>
      <li class="advantage_item">
        <div class="advantage_item__circle">
          <svg
            width="67"
            height="67"
            viewBox="0 0 67 67"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M58.5072 14.6534C54.0318 14.7188 50.3547 18.37 50.2631 22.8456C50.1977 26.0257 51.8988 28.8131 54.4506 30.2789V45.0144C54.4506 52.5131 47.8814 58.6245 39.7943 58.6245C31.9428 58.6245 25.5045 52.8533 25.1512 45.6426C34.6777 43.6796 41.875 35.2256 41.875 25.1227V4.78606C41.875 3.29418 40.815 1.9986 39.3494 1.71069L31.1184 0.0617767C29.4172 -0.278476 27.7684 0.820802 27.4281 2.52207L27.0094 4.57667C26.6691 6.27794 27.7684 7.92685 29.4695 8.26711L33.4869 9.06539V24.9526C33.4869 31.8754 27.9646 37.6074 21.0422 37.6728C14.0543 37.7382 8.36191 32.0848 8.36191 25.1096V9.07848L12.3793 8.28019C14.0805 7.93994 15.1797 6.29102 14.8395 4.58976L14.4338 2.53515C14.0936 0.833889 12.4447 -0.26539 10.7436 0.0748633L2.52559 1.69761C1.05996 1.9986 0 3.28109 0 4.78606V25.1227C0 35.2387 7.21035 43.7058 16.7631 45.6426C17.1033 57.4729 27.2973 67 39.7812 67C52.4746 67 62.8125 57.1327 62.8125 45.0144V30.2789C65.3119 28.8262 67 26.1304 67 23.0289C67 18.3569 63.1789 14.588 58.5072 14.6534ZM58.625 25.1227C57.4734 25.1227 56.5312 24.1805 56.5312 23.0289C56.5312 21.8772 57.4734 20.935 58.625 20.935C59.7766 20.935 60.7188 21.8772 60.7188 23.0289C60.7188 24.1805 59.7766 25.1227 58.625 25.1227Z"
              fill="white"
            />
          </svg>
        </div>
        <div class="advantage_item__title">
          Качественная диагностика и <br />
          лечение
        </div>
        <p class="advantage_item__text">
          Мы придерживаемся взгляда: медицинские услуги должны быть доступными и
          безупречно профессиональными
        </p>
      </li>
      <li class="advantage_item">
        <div class="advantage_item__circle">
          <svg
            width="67"
            height="67"
            viewBox="0 0 67 67"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M58.9789 7.09009H57.7221V12.8353C57.7221 16.1262 54.9931 18.8052 51.6286 18.8052C48.2641 18.8052 45.5281 16.1262 45.5281 12.8353V7.09009H22.6723V12.8353C22.6723 16.1262 19.9362 18.8052 16.5752 18.8052C13.2178 18.8052 10.4782 16.1262 10.4782 12.8353V7.09009H8.02461C3.60101 7.09009 0 10.6195 0 14.944V59.1428C0 63.4707 3.60101 67.0001 8.02461 67.0001H58.9789C63.4061 67.0001 67 63.4707 67 59.1428V14.944C67.0035 10.616 63.4061 7.09009 58.9789 7.09009ZM60.7936 59.7235H6.21351V25.3663H60.79V59.7235H60.7936Z"
              fill="white"
            />
            <path
              d="M33.0976 54.7592C33.2247 54.8594 33.3871 54.9182 33.546 54.9182C33.7048 54.9182 33.8672 54.8594 33.9943 54.7592C34.4321 54.3824 44.6632 45.6263 45.5634 40.244C45.9236 38.087 45.6129 36.3516 44.6385 35.1003C43.6535 33.8385 42.0719 33.1092 39.8054 32.8706C39.6288 32.8568 39.4417 32.8464 39.2511 32.8464C37.1293 32.8464 34.7075 33.9319 33.4965 35.9852C32.2856 33.9319 29.8637 32.8464 27.742 32.8464C27.5513 32.8464 27.3642 32.8533 27.1877 32.8706C24.9212 33.1092 23.3396 33.8385 22.3616 35.1003C21.3837 36.3516 21.073 38.087 21.4331 40.244C22.3334 45.6263 32.6563 54.3789 33.0976 54.7592Z"
              fill="white"
            />
            <path
              d="M16.6176 16.1157C18.4675 16.1157 19.9714 14.6465 19.9714 12.8317V3.28397C19.9714 1.47606 18.4675 0 16.6176 0C14.7676 0 13.2637 1.4726 13.2637 3.28397V12.8351C13.2637 14.6465 14.7676 16.1157 16.6176 16.1157Z"
              fill="white"
            />
            <path
              d="M51.6709 16.1157C53.5173 16.1157 55.0177 14.6465 55.0177 12.8317V3.28397C55.0177 1.47606 53.5173 0 51.6709 0C49.821 0 48.3135 1.4726 48.3135 3.28397V12.8351C48.3135 14.6465 49.8174 16.1157 51.6709 16.1157Z"
              fill="white"
            />
          </svg>
        </div>
        <div class="advantage_item__title">
          Удобный <br />
          график работы
        </div>
        <p class="advantage_item__text">
          МДЦ «ПРОЗДОРОВЬЕ» оказывает помощь во все дни недели, включая выходные и праздничные дни.
        </p>
      </li>
      <li class="advantage_item">
        <div class="advantage_item__circle">
          <svg
            width="59"
            height="78"
            viewBox="0 0 59 78"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M29.5 0C13.2357 0 0 13.2145 0 29.4612C0 52.546 26.727 76.3815 27.8644 77.3825C28.3331 77.7952 28.9166 78 29.5 78C30.0834 78 30.6669 77.7953 31.1356 77.3858C32.273 76.3815 59 52.546 59 29.4612C59 13.2145 45.7643 0 29.5 0ZM29.5 45.5C20.4632 45.5 13.1111 38.2102 13.1111 29.25C13.1111 20.2898 20.4632 13 29.5 13C38.5368 13 45.8889 20.2898 45.8889 29.25C45.8889 38.2102 38.5368 45.5 29.5 45.5Z"
              fill="white"
            />
          </svg>
        </div>
        <div class="advantage_item__title">
          Удобное <br />
          расположение
        </div>
        <p class="advantage_item__text">
          Расположение имеет большое значение, оно определяется не только
          территориальной близостью, но и транспортной доступностью
        </p>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "section_advantages",
};
</script>

<style scoped></style>
