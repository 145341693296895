<template>
  <input
    :value="modelValue"
    @input="$emit('update:modelValue', $event.target.value)"
    v-maska="maska"
    :type="type"
    name="name"
    :placeholder="placeholder"
  />
</template>

<script>
export default {
  name: "DefaultInputModal",
  emits: ['update:modelValue'],
  props: ["type", "placeholder", "maska", "modelValue"],
  data() {
    return {
      defaultDataArgument: null,
    };
  },
  methods: {
    defaultMethod() {
      defaultMethodBody;
    },
  },
  computed: {},
  watch: {},
};
</script>

