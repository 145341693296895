<template>
  <div class="modal-result-message-container">
    <div class="modal-result-message-wrapper">
      <div class="modal-result-message" style="position: relative;">
        <div class="message-wrapper">
          <p id="modal_message">{{ messageModalText }}</p>
        </div>
        <button @click="$store.commit('closeMessageModalState')" class="close-modal" style="padding: 0; position: relative">
          <svg
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M0 0.666708L0.666647 4.38888e-05L13.9996 13.3333L13.3329 14L0 0.666708Z"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M13.3334 0L14 0.666664L0.667064 14L0.000417038 13.3333L13.3334 0Z"
            />
          </svg>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MessageModal',
  data() {
    return {
    }
  },
  methods: {
  },
  computed: {
    messageModalText() {
      return this.$store.state.messageModal.text
    }
  },
  watch: {},
}
</script>

<style></style>
