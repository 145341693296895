<template>
  <div class="modal_container">
    <div class="modal_wrapper">
      <div
        @click.self="$store.commit('toggleRecordModalState', 'recordModal')"
        class="modal_background"
        tabindex="0"
        id="modal_background"
      ></div>
      <div class="modal_record_component">
        <button
          @click.stop="$store.commit('toggleRecordModalState', 'recordModal')"
          class="close-popup"
        >
          <span></span>
          <span></span>
        </button>
        <div class="record">
          <div class="modal_header">
            <div class="modal_header__text">
              <h3>
                Онлайн запись на приём
              </h3>
            </div>
            <p>
              Быстрый и удобный способ регистрации на приём в клинику
              МДЦ «ПРОЗДОРОВЬЕ»
            </p>
          </div>
          <div class="input-group-wrapper">
            <div class="input-group">
              <default-input-modal
                :class="{ error: error['name'] }"
                maska=""
                v-model="nameValue"
                type="text"
                placeholder="Ф.И.О*"
              />
              <default-input-modal
                :class="{ error: error['phone'] }"
                maska="+7 (###) ###-##-##"
                v-model="phoneValue"
                type="text"
                placeholder="Номер телефона*"
              />
              <default-text-area-modal
                :class="{ error: error['comment'] }"
                :rows="4"
                placeholder="Комментарий"
                type="text"
                v-model="textValue"
              />
            </div>
            <div class="select-modal-wrapper">
              <record-main-form
                @error-modal="catchError"
                @succeful-modal="catchSucceful"
                :nameValue="nameValue"
                :phoneValue="phoneValue"
                :textValue="textValue"
                :policyValue="policyValue"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RecordMainForm from "../section/section_record/RecordMainForm.vue";
import DefaultInputModal from "../select/DefaultInputModal.vue";
import DefaultTextAreaModal from "../select/DefaultTextAreaModal.vue";
export default {
  name: "RecordModal",
  components: {
    RecordMainForm,
    DefaultInputModal,
    DefaultTextAreaModal,
  },
  data() {
    return {
      nameValue: "",
      textValue: "",
      policyValue: "0000-0000-0000-0000",
      phoneValue: "",
      policy: "policy",
      error: {},
    };
  },
  props: ["main_phone_number"],
  computed: {
    servicesList() {
      return this.$store.state?.servicesList;
    },
  },
  methods: {
    catchError(error) {
      this.error = error;
    },
    catchSucceful() {
      this.nameValue = "";
      this.textValue = "";
      this.policyValue = "0000-0000-0000-0000";
      this.phoneValue = "";
      this.error = {};
    },
  },
  mounted() {},
};
</script>
<style scoped lang="scss" src="./modal.scss"></style>
