<template>
  <textarea
    :type="type"
    :rows="rows"
    :placeholder="placeholder"
    :value="modelValue"
    @input="$emit('update:modelValue', $event.target.value)"
  ></textarea>
</template>

<script>
export default {
  name: "DefaultTextAreaModal",
  emits: ["update:modelValue"],
  props: ["rows", "type", "placeholder", "modelValue"],
  data() {
    return {
      defaultDataArgument: null,
    };
  },
  methods: {
    defaultMethod() {
      defaultMethodBody;
    },
  },
  computed: {},
  watch: {},
};
</script>

