<template>
  <div class="section_wrapper">
    <div class="start_form__wrapper">
      <div class="start__title">Записаться на приём</div>
      <div class="start__subtitle">
        по <a :href="`tel:${mainPhoneNumber}`">телефону</a> или онлайн
      </div>
      <record-main-form />
    </div>
  </div>
</template>

<script>
import RecordMainForm from "./RecordMainForm.vue";

export default {
  name: "SectionRecord",
  components: {
    RecordMainForm,
  },
  computed: {
    mainPhoneNumber() {
      return this.$store.getters.getContactInfo?.main_phone_number;
    },
  },
};
</script>
