<template>
  <router-view
    :main_phone_number="main_phone_number"
    :priceList="priceList"
    :servicesList="servicesList"
    v-slot="{ Component }"
  >
    <transition mode="out-in" name="fade_router">
      <component :is="Component" /> </transition
  ></router-view>
</template>

<script>
export default {
  name: "services",
  props: ["main_phone_number", "priceList", "servicesList"],
};
</script>

<style scoped></style>
