import HeaderSection from "../components/header/header.vue";
import FooterSection from "../components/footer/footer.vue";
import DoctorModal from "../components/modal/DoctorModal.vue";
import PhoneModal from "../components/modal/PhoneModal.vue";
import ReviewModal from "../components/modal/ReviewModal.vue";
import RecordModal from "../components/modal/RecordModal.vue";
import DirectorModal from "../components/modal/DirectorModal.vue";
import MessageModal from "../components/modal/MessageModal.vue";
import { mapState } from "vuex";
export default {
  name: "app",
  components: {
    HeaderSection,
    FooterSection,
    DirectorModal,
    RecordModal,
    ReviewModal,
    PhoneModal,
    DoctorModal,
    MessageModal,
  },
  data() {
    return {
      specialties: "",
      isActiveLoad: false,
      cookie: false,
    };
  },
  computed: {
    burgerState() {
      return this.$store.state.burgerState;
    },
    stateDirectorModal() {
      return this.$store.state.directorModal.stateActiveModal;
    },
    stateDoctorModal() {
      return this.$store.state.doctorModal.stateActiveModal;
    },
    statePhoneModal() {
      return this.$store.state.phoneModal.stateActiveModal;
    },
    stateRecordModal() {
      return this.$store.state.recordModal.stateActiveModal;
    },
    stateReviewModal() {
      return this.$store.state.reviewModal.stateActiveModal;
    },
    stateMessageModal() {
      return this.$store.state.messageModal.stateActiveModal;
    },
    isActiveLoadData() {
      return !this.servicesList;
    },
    ...mapState([
      "priceList",
      "servicesList",
      "contactInfo",
      "additional_phone_number",
      "address",
      "email",
      "id",
      "instagram",
      "main_phone_number",
      "questions_remain",
      "vkontakte",
      "whatsapp",
      "main_price_list",
      "gastroenterology_price_list",
      "gynecology_price_list",
      "cardiology_price_list",
      "laboratory_research_price_list",
      "neurology_price_list",
      "therapy_price_list",
      "ultrasound_diagnostics_price_list",
      "endocrinology_price_list",
      "certificates_and_licenses",
      "privacy_policy",
    ]),
  },
  methods: {
    cookieToggle() {
      localStorage.setItem("cookieState", true);
      this.cookie = localStorage.getItem("cookieState")
    },
  },
  async mounted() {
    this.$store.dispatch("ACTION__PRICE_LIST");
    this.$store.dispatch("ACTION__SERVICES_LIST");
    this.$store.dispatch("ACTION__CONTACT_INFO");
    this.$store.dispatch("ACTION__DOCTORS");
    this.$store.dispatch("ACTION__REVIEWS");
    this.$store.dispatch("ACTION__MAINSLAIDER");

    if (localStorage.getItem("cookieState")) {
      this.cookie = localStorage.getItem("cookieState");
    }
  },
  watch: {},
};
