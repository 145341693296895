<template>
  <div class="section_wrapper">
    <div class="service_top">
      <h4>Услуги</h4>
      <router-link to="/services/">
        Смотреть все услуги
        <span>
          <svg
            width="24"
            height="14"
            viewBox="0 0 24 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M17.5 13.5L24 7L17.5 0.5L16.793 1.207L22.086 6.5L0 6.5V7.5L22.086 7.5L16.793 12.793L17.5 13.5Z"
              fill="#232323"
            />
          </svg>
        </span>
      </router-link>
    </div>
    <div class="service_bottom" v-if="servicesList">
      <swiper
        :breakpoints="{
          1024: {
            slidesPerView: 4,
            spaceBetween: 32,
          },
          481: {
            slidesPerView: 2,
            spaceBetween: 10,
          },
        }"
        :spaceBetween="5"
        :slidesPerView="1"
        :pagination="{ clickable: true }"
        :navigation="{
          nextEl: '.section_service .swiper-button-next',
          prevEl: '.section_service .swiper-button-prev',
        }"
        class="service_list swiper-wrapper"
      >
        <swiper-slide
          class="service_item swiper-slide"
          :key="item.id"
          v-for="item in servicesList.sort((a, b) => a.id_sort - b.id_sort)"
        >
          <router-link :to="item.slug">
            <img :src="item.url_image" alt="" />
            <div class="service_item__info">
              <p class="service_item__text">{{ item.name }}</p>
            </div>
          </router-link>
        </swiper-slide>
      </swiper>
      <div class="service__mobile_interactive">
        <div class="swiper-button-prev">
          <svg
            width="18"
            height="30"
            viewBox="0 0 18 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M15.1863 0.151261L0.337524 15L15.1863 29.8488L17.6625 27.3743L5.28652 15L17.6625 2.62576L15.1863 0.151261Z"
              fill="#232323"
              fill-opacity="0.2"
            />
          </svg>
        </div>
        <router-link to="/services/">Смотреть все услуги</router-link>
        <div class="swiper-button-next">
          <svg
            width="18"
            height="30"
            viewBox="0 0 18 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M2.81373 29.8487L17.6625 15L2.81373 0.151242L0.337476 2.62574L12.7135 15L0.337477 27.3742L2.81373 29.8487Z"
              fill="#232323"
              fill-opacity="0.2"
            />
          </svg>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SwiperCore, { Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";
import "swiper/components/pagination/pagination.scss";
SwiperCore.use([Navigation, Pagination]);
export default {
  name: "service",
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      defaultDataArgument: null,
    };
  },
  methods: {
    defaultMethod() {
      defaultMethodBody;
    },
  },
  computed: {
    servicesList() {
      return this.$store.state.servicesList;
    },
  },
  watch: {},
};
</script>

<style></style>
