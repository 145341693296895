export default {
  name: "doctor",
  components: {},
  data() {
    return {
      filterDoctor: null,
      acitveFilter: false
    };
  },
  computed: {
    doctorsItems() {
      return this.$store.getters.getDoctors(this.filterDoctor).filter(item=>item.is_shown)
    },
    specialtiesItems() {
      return this.$store.state.servicesList
    }
  },
  methods: {
    toggleFilter(newFilterValue) {
      this.filterDoctor = newFilterValue;
    },
    doctorOpenModal(doctor) {
      const doctorSpecial = this.specialtiesItems.find(item => item.id === doctor.specialty)
      this.$store.commit('toggleRecordModalState', 'recordModal')
      this.$store.commit('updateRecordModalValues', {
        keyModal: 'selectedCategory',
        valueModal: doctorSpecial,
      })
      this.$store.commit('updateRecordModalValues', {
        keyModal: 'selectedDoctor',
        valueModal: doctor,
      })
    }
  },
};
