<template>
  <div>
    <main id="main">
      <section class="section_outer section_bread_crumbs">
        <div class="section_inner">
          <div class="section_wrapper">
            <div class="bread_crumbs__main">
              <router-link to="/"
                >Медицинская клиника «ПРОЗДОРОВЬЕ»</router-link
              >
            </div>
            <div class="bread_crumbs__separator">/</div>
            <div class="bread_crumbs__next">Отзывы</div>
          </div>
        </div>
      </section>
      <section class="section_outer section_service_item">
        <div class="section_inner">
          <div class="section_wrapper">
            <div class="section__title">Отзывы наших клиентов</div>
            <div class="service_item__wrapper">
              <div class="service_item__info">
                <div class="service_item__description">
                  По нашему мнению, пациент должен чувствовать заботливое
                  отношение со стороны клиники с момента записи на прием и до
                  выхода из медицинского центра. Поэтому для нас так важны ваши
                  отзывы, как еще одна точка контакта с пациентом. Оставляя свой
                  отзыв о наших специалистах, вы помогаете нам развиваться,
                  делая еще более эффективной нашу работу.
                </div>
                <button
                  class="review__button"
                  @click="
                    $store.commit('toggleRecordModalState', 'reviewModal')
                  "
                >
                  Оставить отзыв
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="section_outer section_reviews">
        <div class="section_inner">
          <div class="section_wrapper">
            <div class="reviews_filter">
              <div
                class="reviews_filter__rating"
                @click="filterRating = !filterRating"
                :class="{ bottom: filterRating, top: !filterRating }"
              >
                <svg
                  width="13"
                  height="13"
                  viewBox="0 0 13 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M6.33437 0.337158L12.1564 5.62994L11.4838 6.36988L6.82009 2.13019V12.9999H5.82009V2.06743L0.640184 6.38402L0 5.6158L6.33437 0.337158Z"
                    fill="#D5D5D5"
                  />
                </svg>
                Рейтинг
              </div>
              <button
                class="reviews_filter__date"
                @click="filterDate = !filterDate"
                :class="{ bottom: filterDate, top: !filterDate }"
              >
                <svg
                  width="13"
                  height="13"
                  viewBox="0 0 13 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M6.33437 0.337158L12.1564 5.62994L11.4838 6.36988L6.82009 2.13019V12.9999H5.82009V2.06743L0.640184 6.38402L0 5.6158L6.33437 0.337158Z"
                    fill="#D5D5D5"
                  />
                </svg>

                Дата
              </button>
            </div>
            <ul class="reviews_list">
              <li
                class="reviews_item"
                :key="index"
                v-for="(review, index) in paginationReviews"
              >
                <div class="reviews_item__text">
                  {{ review.text }}
                </div>
                <div class="reviews_item__bottom">
                  <div class="reviews_item__left">
                    <div class="reviews_item__author">{{ review.name }}</div>
                    <div class="reviews_item__rating">
                      Оценка:
                      <div class="reviews_item__rating_stars">
                        <div
                          :key="item"
                          v-for="item in review.rating"
                          class="reviews_item__star active"
                        >
                          <svg
                            width="19"
                            height="19"
                            viewBox="0 0 19 19"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M8.47318 0.690983C8.77253 -0.230327 10.0759 -0.230328 10.3753 0.690983L11.9145 5.42819C12.0484 5.84021 12.4323 6.11917 12.8656 6.11917H17.8465C18.8153 6.11917 19.218 7.35879 18.4343 7.92819L14.4046 10.8559C14.0541 11.1106 13.9075 11.562 14.0414 11.974L15.5806 16.7112C15.8799 17.6325 14.8254 18.3986 14.0417 17.8292L10.012 14.9015C9.66153 14.6468 9.18694 14.6468 8.83645 14.9015L4.80674 17.8292C4.02303 18.3986 2.96855 17.6325 3.2679 16.7112L4.80711 11.974C4.94098 11.562 4.79433 11.1106 4.44384 10.8559L0.414132 7.92819C-0.369582 7.35879 0.0331936 6.11917 1.00192 6.11917H5.98291C6.41613 6.11917 6.80009 5.84021 6.93397 5.42819L8.47318 0.690983Z"
                              fill="#0DC2B6"
                            />
                          </svg>
                        </div>
                        <div
                          class="reviews_item__star"
                          :key="item"
                          v-for="item in 5 - review.rating"
                        >
                          <svg
                            width="19"
                            height="19"
                            viewBox="0 0 19 19"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M8.47318 0.690983C8.77253 -0.230327 10.0759 -0.230328 10.3753 0.690983L11.9145 5.42819C12.0484 5.84021 12.4323 6.11917 12.8656 6.11917H17.8465C18.8153 6.11917 19.218 7.35879 18.4343 7.92819L14.4046 10.8559C14.0541 11.1106 13.9075 11.562 14.0414 11.974L15.5806 16.7112C15.8799 17.6325 14.8254 18.3986 14.0417 17.8292L10.012 14.9015C9.66153 14.6468 9.18694 14.6468 8.83645 14.9015L4.80674 17.8292C4.02303 18.3986 2.96855 17.6325 3.2679 16.7112L4.80711 11.974C4.94098 11.562 4.79433 11.1106 4.44384 10.8559L0.414132 7.92819C-0.369582 7.35879 0.0331936 6.11917 1.00192 6.11917H5.98291C6.41613 6.11917 6.80009 5.84021 6.93397 5.42819L8.47318 0.690983Z"
                              fill="#0DC2B6"
                            />
                          </svg>
                        </div>
                        <div class="reviews_item__rating_number">
                          {{ review.rating }},0
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="reviews_item__right_date">
                    {{ formatDate(new Date(review.updated)) }}
                  </div>
                </div>
              </li>
            </ul>
            <div class="reviews_paginated" v-if="filteredReviews?.length">
              <div @click="prevPage" class="reviews_paginated__prev">
                <svg
                  width="36"
                  height="36"
                  viewBox="0 0 36 36"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    x="0.76087"
                    y="0.76087"
                    width="34.4783"
                    height="34.4783"
                    rx="3.80435"
                    stroke="#F3F3F3"
                    stroke-width="1.52174"
                  />
                  <path
                    d="M20.3995 9.14593L11.8223 17.9998L20.3995 26.8538L21.8299 25.3783L14.681 17.9998L21.8299 10.6214L20.3995 9.14593Z"
                    fill="#F3F3F3"
                  />
                </svg>
              </div>
              <div class="reviews_paginated__list">
                <div
                  class="reviews_paginated__item"
                  v-if="page > 1"
                  :class="{ active: 1 === page }"
                  @click="togglePage(1)"
                >
                  1
                </div>
                <div class="reviews_paginated__moresix" v-if="page > 2">
                  ...
                </div>
                <div
                  class="reviews_paginated__item"
                  @click="togglePage(item)"
                  :key="item"
                  v-for="item in renderPaginationList"
                  :class="{ active: item === page }"
                >
                  {{ item }}
                </div>
                <div
                  class="reviews_paginated__moresix"
                  v-if="maxPage > page + 6"
                >
                  ...
                </div>
                <div
                  :class="{ active: maxPage === page && page > 1 }"
                  @click="togglePage(maxPage)"
                  class="reviews_paginated__item"
                >
                  {{ maxPage }}
                </div>
              </div>
              <div @click="nextPage" class="reviews_paginated__next">
                <svg
                  width="36"
                  height="36"
                  viewBox="0 0 36 36"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    x="35.2391"
                    y="35.2391"
                    width="34.4783"
                    height="34.4783"
                    rx="3.80435"
                    transform="rotate(-180 35.2391 35.2391)"
                    stroke="#0DC2B6"
                    stroke-width="1.52174"
                  />
                  <path
                    d="M15.6005 26.8541L24.1777 18.0002L15.6005 9.14623L14.1701 10.6217L21.319 18.0002L14.1701 25.3786L15.6005 26.8541Z"
                    fill="#0DC2B6"
                  />
                </svg>
              </div>
            </div>
              <div @click="nextPageMobile" class="reviews_mobile_next">
                Загрузить ещё
              </div>
          </div>
        </div>
      </section>
    </main>
  </div>
</template>

<script>
export default {
  name: "review",
  data() {
    return {
      filterDate: false,
      filterRating: false,
      page: 1,
      maxItems: 3,
      startIndexPagination: 0,
      endIndexPagination: 6,
    };
  },
  computed: {
    reviews() {
      return (
        this.$store.state?.reviews?.filter((item) => item.is_moderated) || []
      );
    },
    filteredReviews() {
      return this.reviews?.sort((a, b) => {
        if (!this.filterDate && !this.filterRating)
          return (
            new Date(a.updated) - new Date(b.updated) || a.rating - b.rating
          );
        if (!this.filterDate && this.filterRating)
          return (
            b.rating - a.rating || new Date(a.updated) - new Date(b.updated)
          );
        if (this.filterDate && !this.filterRating)
          return (
            new Date(b.updated) - new Date(a.updated) || a.rating - b.rating
          );
        if (this.filterDate && this.filterRating)
          return (
            b.rating - a.rating || new Date(b.updated) - new Date(a.updated)
          );
      });
    },
    maxPage() {
      return Math.ceil(this.filteredReviews.length / this.maxItems);
    },
    paginationList() {
      return Array(this.maxPage)
        .join()
        .split(",")
        .map((item, index) => index + 1);
    },
    renderPaginationList() {
      return this.paginationList.slice(
        this.startIndexPagination,
        this.endIndexPagination
      );
    },
    maxPagesInPaginationRender() {
      if (this.maxPage > this.page + 6) return this.maxPage - 1;
      return this.maxPage;
    },
    startIndex() {
      return (this.page - 1) * this.maxItems;
    },
    endIndex() {
      return this.page * this.maxItems;
    },
    paginationReviews() {
      return this.filteredReviews.slice(this.startIndex, this.endIndex);
    },
  },
  methods: {
    showRenderItemPagination(item) {
      if (this.maxPage < this.page) return item + 1;
      return item;
    },
    togglePage(index) {
      this.page = index;
      this.startIndexPagination = this.page - 1;
      if (index === this.maxPage) return;
      this.endIndexPagination =
        6 + this.startIndexPagination >= this.maxPage
          ? this.maxPage - 1
          : 6 + this.startIndexPagination;
    },
    nextPage() {
      if (this.page + 1 > this.maxPage) return;
      this.togglePage(this.page + 1);
    },
    nextPageMobile() {
      if (this.page + 1 > this.maxPage) return this.togglePage(1);;
      this.togglePage(this.page + 1);
    },
    prevPage() {
      if (this.page - 1 === 0) return;
      this.togglePage(this.page - 1);
    },
    formatDate(date) {
      var dd = date.getDate();
      if (dd < 10) dd = "0" + dd;

      var mm = date.getMonth() + 1;
      if (mm < 10) mm = "0" + mm;

      var yy = date.getFullYear();

      return dd + "." + mm + "." + yy;
    },
  },
  mounted() {},
};
</script>

<style scoped></style>
