import { createStore } from "vuex";
import { api } from "./api";
export default createStore({
  state: {
    recordModal: {
      stateActiveModal: false,
      selectedCategory: "",
      selectedDoctor: "",
      selectedDate: "",
      selectedTime: "",
    },
    messageModal: {
      text: "",
      steateActiveModal: false,
    },
    doctorModal: {
      stateActiveModal: false,
    },
    phoneModal: {
      stateActiveModal: false,
    },
    reviewModal: {
      stateActiveModal: false,
    },
    directorModal: {
      stateActiveModal: false,
    },
    priceList: null,
    servicesList: null,
    contactInfo: null,
    additional_phone_number: "",
    address: "",
    email: "",
    id: "",
    instagram: "",
    main_phone_number: "",
    questions_remain: "",
    vkontakte: "",
    whatsapp: "",
    main_price_list: null,
    gastroenterology_price_list: null,
    gynecology_price_list: null,
    cardiology_price_list: null,
    laboratory_research_price_list: null,
    neurology_price_list: null,
    therapy_price_list: null,
    ultrasound_diagnostics_price_list: null,
    endocrinology_price_list: null,
    certificates_and_licenses: null,
    privacy_policy: null,
    doctors: [],
    burgerState: false,
    menuAboutState: false,
    menuServicesState: false,
    menuSearchState: false,
    reviews: [],
    slider: [],
  },
  mutations: {
    searchToggle(state) {
      state.menuSearchState = !state.menuSearchState;
    },
    activeServiceMenu(state) {
      state.menuServicesState = !state.menuServicesState;
      state.menuAboutState = false;
    },
    activeAboutMenu(state) {
      state.menuAboutState = !state.menuAboutState;
      state.menuServicesState = false;
    },
    hiddenMenu(state) {
      state.menuAboutState = false;
      state.menuServicesState = false;
    },
    toggleBurgerState(state) {
      state.burgerState = !state.burgerState;
      document.body.classList.toggle("no-scroll");
    },
    updatePriceList(state, newPriceList) {
      state.priceList = newPriceList;
      for (const key of Object.keys(newPriceList)) {
        if (!state[key]) state[key] = newPriceList[key];
      }
    },
    updateServicesList(state, newServicesList) {
      state.servicesList = newServicesList;
    },
    updateContactInfo(state, newContactInfo) {
      for (const key of Object.keys(newContactInfo)) {
        if (state[key] === "") state[key] = newContactInfo[key];
      }
      state.contactInfo = newContactInfo;
    },
    uploadRecordModalValues(state) {
      state.recordModal.selectedCategory = "";
      state.recordModal.selectedDate = "";
      state.recordModal.selectedDoctor = "";
      state.recordModal.selectedTime = "";
    },
    toggleRecordModalState(state, recordModalName) {
      state[recordModalName].stateActiveModal = !state[recordModalName]
        .stateActiveModal;
      document.body.classList.toggle("no-scroll");
    },
    closeMessageModalState(state) {
      state.messageModal.stateActiveModal = false;
    },
    showMessageModalState(state) {
      state.messageModal.stateActiveModal = true;
    },
    toggleMessageModalText(state, newText) {
      state.messageModal.text = newText;
    },
    updateRecordModalValues(state, recordModalArgs) {
      switch (recordModalArgs.keyModal) {
        case "selectedCategory":
          state.recordModal = {
            stateActiveModal: state.recordModal.stateActiveModal,
            selectedCategory: recordModalArgs.valueModal,
            selectedDoctor: "",
            selectedDate: "",
            selectedTime: "",
          };
          break;
        case "selectedDoctor":
          state.recordModal = {
            stateActiveModal: state.recordModal.stateActiveModal,
            selectedCategory: state.recordModal.selectedCategory,
            selectedDoctor: recordModalArgs.valueModal,
            selectedDate: "",
            selectedTime: "",
          };
          break;
        case "selectedDate":
          state.recordModal = {
            stateActiveModal: state.recordModal.stateActiveModal,
            selectedCategory: state.recordModal.selectedCategory,
            selectedDoctor: state.recordModal.selectedDoctor,
            selectedDate: recordModalArgs.valueModal,
            selectedTime: "",
          };
          break;
        case "selectedTime":
          state.recordModal = {
            stateActiveModal: state.recordModal.stateActiveModal,
            selectedCategory: state.recordModal.selectedCategory,
            selectedDoctor: state.recordModal.selectedDoctor,
            selectedDate: state.recordModal.selectedDate,
            selectedTime: recordModalArgs.valueModal,
          };
          break;

        default:
          break;
      }
    },
    updateDoctors(state, newDoctors) {
      state.doctors = newDoctors;
    },
    updateReviews(state, newReviews) {
      state.reviews = newReviews;
    },
    updateMainSlider(state, newSlider) {
      state.slider = newSlider;
    },
  },
  actions: {
    ACTION__PRICE_LIST(context) {
      api.fetch("GET", "price_list/").then((data) => {
        context.commit("updatePriceList", data[0]);
      });
    },
    ACTION__SERVICES_LIST(context) {
      api.fetch("GET", "specialties/").then((data) => {
        context.commit("updateServicesList", data);
      });
    },
    ACTION__CONTACT_INFO(context) {
      api.fetch("GET", "contact_information/").then((data) => {
        context.commit("updateContactInfo", data[0]);
      });
    },
    ACTION__DOCTORS(context) {
      api.fetch("GET", "users/").then((data) => {
        context.commit("updateDoctors", [...data]);
      });
    },
    ACTION__REVIEWS(context) {
      api
        .fetch("GET", "reviews/")
        .then((data) => context.commit("updateReviews", data));
    },
    ACTION__MAINSLAIDER(context) {
      api
        .fetch("GET", "main_slider/")
        .then((data) => context.commit("updateMainSlider", data));
    },
  },
  getters: {
    getContactInfo: (state) => {
      return state.contactInfo;
    },
    getDoctors: (state) => (filterDoctor) => {
      return state.doctors.filter((item) =>
        filterDoctor ? item.specialty === filterDoctor : true
      );
    },
  },
});
