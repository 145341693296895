<template>
  <div>
    <main id="main">
      <section class="section_outer section_bread_crumbs">
        <div class="section_inner">
          <div class="section_wrapper">
            <div class="bread_crumbs__main">
              <router-link to="/"
                >Медицинская клиника «ПРОЗДОРОВЬЕ»</router-link
              >
            </div>
            <div class="bread_crumbs__separator">/</div>
            <div class="bread_crumbs__next">Врачи</div>
          </div>
        </div>
      </section>
      <section class="section_outer section_service_item">
        <div class="section_inner">
          <div class="section_wrapper">
            <div class="section__title">Специалисты ООО МДЦ «ПРОЗДОРОВЬЕ»</div>
            <div class="service_item__wrapper">
              <img
                class="service_item__image"
                src="../../../public/img/carteology.png"
                alt=""
              />
              <div class="service_item__info">
                <div class="service_item__title">
                  Медицинская клиника «ПРОЗДОРОВЬЕ»
                </div>
                <div class="service_item__description">
                  Мы дорожим своей репутацией и это является залогом того, что
                  лечебная услуга будет оказана качественно, в полном объёме.
                  После постановки диагноза и выдачи заключения мы предлагаем
                  пациенту на выбор несколько вариантов лечения в соответствии
                  именно с его ситуацией, реализуя, таким образом, его право на
                  выбор лечения.
                </div>
                <div class="service_item__description">
                  При составлении схемы лечения, помимо диагностических данных,
                  учитывается множество других факторов (возраст, пол, общее
                  состояние здоровья, перенесенные заболевания и хирургические
                  вмешательства, наличие хронических заболеваний, принимаемые
                  лекарственные средства, длительность приема, и т.д.). Политика
                  медицинского центра «ПРОЗДОРОВЬЕ» – занимать «средний» сегмент
                  стоимости услуг при неизменно высоком качестве медицинской
                  помощи.
                </div>

                <button
                  class="medic__question"
                  @click="
                    $store.commit('toggleRecordModalState', 'doctorModal')
                  "
                >
                  Задать вопрос врачам
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="section_outer section_medic_filter" v-if="specialtiesItems">
        <div class="section_inner">
          <div class="section_wrapper">
            <div class="medic_filter__main">
              <div class="section__title">Врачи</div>
              <div class="medic_filter__wrapper">
                <div
                  @blur="acitveFilter = false"
                  @click.stop="acitveFilter = !acitveFilter"
                  class="medic_filter__block"
                  tabindex="0"
                >
                  Выберите спициальность
                  <svg
                    width="13"
                    height="7"
                    viewBox="0 0 13 7"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M1 1L6.5 6L12 1" stroke="#21B0BA" />
                  </svg>
                </div>
                <transition name="fade">
                  <ul v-if="acitveFilter" class="medic_filter__menu">
                    <li
                      @click.stop="toggleFilter(null)"
                      :class="{ active: !filterDoctor }"
                      class="medic_filter__item"
                    >
                      Показать все
                    </li>
                    <li
                      @click.self="toggleFilter(item.id)"
                      :class="{ active: item.id === filterDoctor }"
                      v-for="(item, index) in specialtiesItems.sort((a, b) => a.id_sort - b.id_sort)"
                      :key="index"
                      class="medic_filter__item"
                    >
                      {{ item.name }}
                    </li>
                  </ul>
                </transition>
              </div>
            </div>
            <ul class="service_med__list">
              <li
                class="service_med__item"
                :key="index"
                v-for="(doctor, index) in doctorsItems"
              >
                <img :src="doctor.photo" alt="" />
                <div
                  class="service_med__item_name"
                  v-html="
                    `${doctor.surname} ${doctor.name} ${doctor.patronymic}`
                  "
                ></div>
                <div
                  class="service_med__item_work"
                  v-html="doctor.position"
                ></div>
                <div class="service_med__item_date">
                  {{ doctor.experience }}
                </div>
                <button
                  class="service_med__item_button"
                  @click="doctorOpenModal(doctor)"
                >
                  Записаться
                </button>
              </li>
            </ul>
          </div>
        </div>
      </section>
    </main>
  </div>
</template>

<script src="./doctor.js"></script>

<style scoped></style>
