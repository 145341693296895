<template>
  <div class="section_wrapper">
    <div class="question_left">
      <div class="question_left__title">Остались вопросы?</div>
      <p class="question_left__text">
        Мы с удовольствем ответим на них каждый день с 9:00 до 20:00
      </p>
      <p class="question_left__text_telephone">
        Оператор клиники перезвонит Вам<br />
        в течение часа и ответит на ваши вопросы
      </p>
      <div class="question_left__form">
        <default-input-modal
          class="question_left__email"
          v-model="mailValue"
          type="text"
          placeholder="Электронная почта"
          :class="{ error: error['email'] }"
        />
        <button
          class="question_left__submit"
          :class="{disabled: !mailValue.includes('@')}"
          @click="sendEmail"
        >
          Отправить
        </button>
      </div>
    </div>
    <div class="question_right">
      <p class="question_right__number">
        {{ parseInt(questions_remain.match(/\d+/)) }}
      </p>
      <p class="question_right__text">
        {{ messageCallBack }}
      </p>
    </div>
  </div>
</template>

<script>
import { api } from "../../../store/api";
import DefaultInputModal from "../../select/DefaultInputModal.vue";
export default {
  components: { DefaultInputModal },
  name: "form_email",
  data() {
    return {
      mailValue: "",
      error: {},
    };
  },
  props: {
    questions_remain: String,
  },
  computed: {
    messageCallBack() {
      return this.questions_remain
        .split(" ")
        .splice(1)
        .toString()
        .replaceAll(",", " ");
    },
  },
  methods: {
    sendEmail() {
      const postObject = {
        email: this.mailValue,
      };
      api.fetch("POST", "easy_emails/", postObject).then((res) => {
          console.log(res)
        if (res?.id) {
          this.$store.commit("toggleMessageModalText", "Ваш запрос отправлен!");
          this.$store.commit("showMessageModalState");
          this.error = {};
          this.mailValue = ""
        } else {
          this.$store.commit(
            "toggleMessageModalText",
            "Проверьте поля на корректность ввода!"
          );
          this.$store.commit("showMessageModalState");
          this.error = res;
        }
      });
    },
  },
};
</script>

<style scoped></style>
