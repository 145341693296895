import btn_scroll from "../button/btn_scroll/btn_scroll.vue";
import { mapState } from "vuex";
export default {
  name: "header_section",
  components: {
    btn_scroll,
  },
  data() {
    return {
      searchValue: "",
    };
  },
  computed: {
    ...mapState([
      "main_price_list",
      "additional_phone_number",
      "address",
      "email",
      "id",
      "instagram",
      "main_phone_number",
      "vkontakte",
      "whatsapp",
      "certificates_and_licenses",
      "privacy_policy",
    ]),
    menuSearchState() {
      return this.$store.state.menuSearchState
    },
    menuAboutState() {
      return this.$store.state.menuAboutState
    },
    menuServicesState() {
      return this.$store.state.menuServicesState
    },
    burgerState() {
      return this.$store.state.burgerState;
    },
    filteredItems() {
      if (!this.searchValue) return [];
      return this.servicesList
        ?.filter((item) =>
          item.name.toLowerCase().includes(this.searchValue.toLowerCase()) ||
          item.first_text.toLowerCase().includes(this.searchValue.toLowerCase())
        )
        .sort((a, b) => {
          const numberA = a.name.toLowerCase().indexOf(
            this.searchValue.toLowerCase())
          const numberB = b.name.toLowerCase().indexOf(
            this.searchValue.toLowerCase())
          return numberA - numberB
        }
        )
        .slice(0, 3);
    },
    servicesList() {
      return this.$store.state.servicesList;
    },
  },
  methods: {
    activeServiceMenu() {
      this.$store.commit("activeServiceMenu");
    },
    activeAboutMenu() {
      this.$store.commit("activeAboutMenu");
    },
    hiddenMenu() {
      this.$store.commit("hiddenMenu");
    },
    showBurger() {
      this.$store.commit("toggleBurgerState");
    },
    searchToggle() {
      this.$store.commit("searchToggle");
      this.searchValue = "";
    },
  },
  mounted() {},
};
