<template>
  <div class="section_wrapper">
    <div class="free_left">
      <svg
        width="98"
        height="98"
        viewBox="0 0 98 98"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M85.75 0H12.25C5.49336 0 0 5.49336 0 12.25V67.375C0 74.1316 5.49336 79.625 12.25 79.625H30.625V95.7031C30.625 97.0621 31.7352 98 32.9219 98C33.3812 98 33.8598 97.866 34.2809 97.5406L58.1875 79.625H85.75C92.5066 79.625 98 74.1316 98 67.375V12.25C98 5.49336 92.5066 0 85.75 0ZM88.8125 67.375C88.8125 69.0594 87.4344 70.4375 85.75 70.4375H55.125L52.675 72.275L39.8125 81.9219V70.4375H12.25C10.5656 70.4375 9.1875 69.0594 9.1875 67.375V12.25C9.1875 10.5656 10.5656 9.1875 12.25 9.1875H85.75C87.4344 9.1875 88.8125 10.5656 88.8125 12.25V67.375Z"
          fill="white"
        />
      </svg>
      <div class="free_left__text">
        <h2>БЕСПЛАТНАЯ КОНСУЛЬТАЦИЯ</h2>
        <p>Мы позвоним Вам и ответим на все вопросы!</p>
      </div>
    </div>
    <div class="free_right">
      <default-input-modal
        class="free_input"
        maska="+7 (###) ###-##-##"
        v-model="phoneValue"
        type="text"
        placeholder="Номер телефона*"
        :class="{ error: error['phone'] }"
      />
      <button @click="sendPhone" class="phoneSubmit">
        <svg
          width="22"
          height="24"
          viewBox="0 0 22 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M-9.94395e-07 13.557L15.9846 13.557L7.667 21.7984L9.889 24L22 12L9.889 8.48571e-07L7.667 2.20164L15.9846 10.443L-7.22156e-07 10.443L-9.94395e-07 13.557Z"
            fill="#DFDFDF"
          />
        </svg>
      </button>
    </div>
  </div>
</template>

<script>
import DefaultInputModal from '../../select/DefaultInputModal.vue';
import { api } from "../../../store/api";
export default {
  components: { DefaultInputModal },
  name: "form_phone",
  data() {
    return {
      phoneValue: "",
      error: {},
    };
  },
  methods: {
    sendPhone() {
      const postObject = {
        phone: this.phoneValue,
      };
      api.fetch("POST", "easy_call_backs/", postObject).then((res) => {
        if (res?.id) {
          this.$store.commit(
            "toggleMessageModalText",
            "Мы позвоним вам!"
          );
          this.$store.commit("showMessageModalState");
          this.error = {};
          this.phoneValue = ""
        } else {
          this.$store.commit(
            "toggleMessageModalText",
            "Проверьте поля на корректность ввода!"
          );
          this.$store.commit("showMessageModalState");
          this.error = res;
        }
      });
    },
    // phoneChange() {
    //     let input = document.querySelector('.free_input').value;
    //     if (input.length < 18) {
    //         document.querySelector('.phoneSubmit').classList.add('disabled');
    //     } else {
    //         document.querySelector('.phoneSubmit').classList.remove('disabled');
    //     }
    // },
    // sendPhone() {
    //     function getCookie(name) {
    //         let cookieValue = null;
    //         if (document.cookie && document.cookie != '') {
    //             let cookies = document.cookie.split(';');
    //             for (let i = 0; i < cookies.length; i++) {
    //                 let cookie = cookies[i].trim();
    //                 // Does this cookie string begin with the name we want?
    //                 if (cookie.substring(0, name.length + 1) == (name + '=')) {
    //                     cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
    //                     break;
    //                 }
    //             }
    //         }
    //         return cookieValue;
    //     }
    //     let formData = new FormData();
    //     let b = getCookie('csrftoken');
    //     formData.append("csrfmiddlewaretoken", b);
    //     formData.append("phone", document.querySelector('.free_input').value);
    //     let xhr = new XMLHttpRequest();
    //     xhr.open('POST', this.url, false);
    //     let counter = 0;
    //     document.querySelector('.modal-result-message').addEventListener('mouseover', function () {
    //         counter = 0;
    //         let interval = setInterval(() => {
    //             counter -= 1;
    //         }, 1000);
    //     })
    //     document.querySelector('.modal-result-message').addEventListener('mouseout', function () {
    //         counter = 0;
    //         let interval = setInterval(() => {
    //             counter += 1;
    //         }, 1000);
    //     })
    //     //управление уведомлением
    //     function hideMessage() {
    //         document.querySelector('.modal-result-message-wrapper').classList.remove('active');
    //     }
    //     document.querySelector('.start__submit').classList.add('disabled');
    //     document.querySelector('.close-modal').onclick = function () {
    //         document.querySelector('.start__submit').classList.remove('disabled');
    //         clearInterval(interval);
    //         hideMessage();
    //     }
    //     let interval = setInterval(() => {
    //         counter += 1;
    //         console.log(counter);
    //         if (counter === 2) {
    //             document.querySelector('.start__submit').classList.remove('disabled');
    //             clearInterval(interval);
    //             hideMessage();
    //         }
    //         document.querySelector('.close-modal').onclick = function () {
    //             document.querySelector('.start__submit').classList.remove('disabled');
    //             clearInterval(interval);
    //             hideMessage();
    //         }
    //     }, 1000);
    //     document.querySelector('.modal-result-message-wrapper').classList.add('active');
    //     xhr.send(formData);
    //     if (xhr.status === 201) {
    //         if (document.querySelector('.free_input').value.length < 11) {
    //             document.querySelector('#modal_message').textContent = 'Неверно введен номер телефона. Повторите еще раз.';
    //         } else {
    //             document.querySelector('#modal_message').textContent = 'Спасибо, что оставили заявку. В ближайшее время с Вами свяжется оператор!';
    //         }
    //     } else {
    //         document.querySelector('#modal_message').textContent = 'Произошла ошибка. Повторите еще раз.';
    //     }
    // }
  },
};
</script>

<style scoped></style>
