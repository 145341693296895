<template>
  <div class="review__starts">
    <div
      @click="addStarState(1)"
      class="review__star"
      :class="{ active: starState >= 1 }"
    >
      <svg
        width="37"
        height="37"
        viewBox="0 0 33 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15.5489 0.927051C15.8483 0.00573989 17.1517 0.00574008 17.4511 0.927051L20.429 10.0922C20.5629 10.5042 20.9468 10.7832 21.3801 10.7832H31.0169C31.9856 10.7832 32.3884 12.0228 31.6046 12.5922L23.8083 18.2566C23.4578 18.5112 23.3112 18.9626 23.445 19.3746L26.423 28.5398C26.7223 29.4611 25.6678 30.2272 24.8841 29.6578L17.0878 23.9934C16.7373 23.7388 16.2627 23.7388 15.9122 23.9934L8.11587 29.6578C7.33216 30.2272 6.27768 29.4611 6.57703 28.5398L9.55497 19.3746C9.68884 18.9626 9.54218 18.5112 9.1917 18.2566L1.39535 12.5922C0.611639 12.0228 1.01442 10.7832 1.98314 10.7832H11.6199C12.0532 10.7832 12.4371 10.5042 12.571 10.0922L15.5489 0.927051Z"
          fill="#FBFBFB"
        ></path>
      </svg>
    </div>
    <div
      @click="addStarState(2)"
      class="review__star"
      :class="{ active: starState >= 2 }"
    >
      <svg
        width="37"
        height="37"
        viewBox="0 0 33 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15.5489 0.927051C15.8483 0.00573989 17.1517 0.00574008 17.4511 0.927051L20.429 10.0922C20.5629 10.5042 20.9468 10.7832 21.3801 10.7832H31.0169C31.9856 10.7832 32.3884 12.0228 31.6046 12.5922L23.8083 18.2566C23.4578 18.5112 23.3112 18.9626 23.445 19.3746L26.423 28.5398C26.7223 29.4611 25.6678 30.2272 24.8841 29.6578L17.0878 23.9934C16.7373 23.7388 16.2627 23.7388 15.9122 23.9934L8.11587 29.6578C7.33216 30.2272 6.27768 29.4611 6.57703 28.5398L9.55497 19.3746C9.68884 18.9626 9.54218 18.5112 9.1917 18.2566L1.39535 12.5922C0.611639 12.0228 1.01442 10.7832 1.98314 10.7832H11.6199C12.0532 10.7832 12.4371 10.5042 12.571 10.0922L15.5489 0.927051Z"
          fill="#FBFBFB"
        ></path>
      </svg>
    </div>
    <div
      @click="addStarState(3)"
      class="review__star"
      :class="{ active: starState >= 3 }"
    >
      <svg
        width="37"
        height="37"
        viewBox="0 0 33 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15.5489 0.927051C15.8483 0.00573989 17.1517 0.00574008 17.4511 0.927051L20.429 10.0922C20.5629 10.5042 20.9468 10.7832 21.3801 10.7832H31.0169C31.9856 10.7832 32.3884 12.0228 31.6046 12.5922L23.8083 18.2566C23.4578 18.5112 23.3112 18.9626 23.445 19.3746L26.423 28.5398C26.7223 29.4611 25.6678 30.2272 24.8841 29.6578L17.0878 23.9934C16.7373 23.7388 16.2627 23.7388 15.9122 23.9934L8.11587 29.6578C7.33216 30.2272 6.27768 29.4611 6.57703 28.5398L9.55497 19.3746C9.68884 18.9626 9.54218 18.5112 9.1917 18.2566L1.39535 12.5922C0.611639 12.0228 1.01442 10.7832 1.98314 10.7832H11.6199C12.0532 10.7832 12.4371 10.5042 12.571 10.0922L15.5489 0.927051Z"
          fill="#FBFBFB"
        ></path>
      </svg>
    </div>
    <div
      @click="addStarState(4)"
      class="review__star"
      :class="{ active: starState >= 4 }"
    >
      <svg
        width="37"
        height="37"
        viewBox="0 0 33 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15.5489 0.927051C15.8483 0.00573989 17.1517 0.00574008 17.4511 0.927051L20.429 10.0922C20.5629 10.5042 20.9468 10.7832 21.3801 10.7832H31.0169C31.9856 10.7832 32.3884 12.0228 31.6046 12.5922L23.8083 18.2566C23.4578 18.5112 23.3112 18.9626 23.445 19.3746L26.423 28.5398C26.7223 29.4611 25.6678 30.2272 24.8841 29.6578L17.0878 23.9934C16.7373 23.7388 16.2627 23.7388 15.9122 23.9934L8.11587 29.6578C7.33216 30.2272 6.27768 29.4611 6.57703 28.5398L9.55497 19.3746C9.68884 18.9626 9.54218 18.5112 9.1917 18.2566L1.39535 12.5922C0.611639 12.0228 1.01442 10.7832 1.98314 10.7832H11.6199C12.0532 10.7832 12.4371 10.5042 12.571 10.0922L15.5489 0.927051Z"
          fill="#FBFBFB"
        ></path>
      </svg>
    </div>
    <div
      @click="addStarState(5)"
      class="review__star"
      :class="{ active: starState >= 5 }"
    >
      <svg
        width="37"
        height="37"
        viewBox="0 0 33 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15.5489 0.927051C15.8483 0.00573989 17.1517 0.00574008 17.4511 0.927051L20.429 10.0922C20.5629 10.5042 20.9468 10.7832 21.3801 10.7832H31.0169C31.9856 10.7832 32.3884 12.0228 31.6046 12.5922L23.8083 18.2566C23.4578 18.5112 23.3112 18.9626 23.445 19.3746L26.423 28.5398C26.7223 29.4611 25.6678 30.2272 24.8841 29.6578L17.0878 23.9934C16.7373 23.7388 16.2627 23.7388 15.9122 23.9934L8.11587 29.6578C7.33216 30.2272 6.27768 29.4611 6.57703 28.5398L9.55497 19.3746C9.68884 18.9626 9.54218 18.5112 9.1917 18.2566L1.39535 12.5922C0.611639 12.0228 1.01442 10.7832 1.98314 10.7832H11.6199C12.0532 10.7832 12.4371 10.5042 12.571 10.0922L15.5489 0.927051Z"
          fill="#FBFBFB"
        ></path>
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  name: "ModalStar",
  data() {
    return {
      starState: 1,
    };
  },
  methods: {
    addStarState(index) {
      this.starState = index;
      this.$emit('startStateUpdate', this.starState)
    },
  },
  computed: {},
  watch: {},
};
</script>

<style lang="scss" scoped>
.review__star {
  display: flex;
  cursor: pointer;
  margin-right: 5px;
  &:last-child {
    margin-right: 0;
  }
  svg {
    fill: #0dc2b61e;
    transition: all 0.35s ease;
    path {
      transition: all 0.35s ease;
      fill: #0dc2b61e;
    }
  }
}
.review__star.active {
  svg {
    fill: #0DC2B6;
    transition: all 0.35s ease;
    path {
      transition: all 0.35s ease;
      fill: #0DC2B6;
    }
  }
}
</style>
