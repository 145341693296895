<template>
  <div>
    <footer class="section_outer section_footer">
      <div class="section_inner">
        <div class="section_wrapper">
          <div class="footer__top">
            <div class="footer__map_site">
              <div class="footer__title">
                Карта сайта
              </div>
              <ul>
                <li>
                  <router-link to="/">
                    Главная
                  </router-link>
                </li>
                <li>
                  <router-link to="/services/">
                    Услуги
                  </router-link>
                </li>
                <li>
                  <router-link to="/doctor">
                    Врачи
                  </router-link>
                </li>
                <li>
                  <router-link to="/review">
                    Отзывы
                  </router-link>
                </li>
                <li>
                  <router-link to="/contact">
                    Контакты
                  </router-link>
                </li>
                <li>
                  <a :href="certificates_and_licenses" target="_blank">
                    Сертификаты и лицензии
                  </a>
                </li>
                <li>
                  <a :href="privacy_policy" target="_blank">
                    Политика конфенденциональности
                  </a>
                </li>
              </ul>
            </div>
            <div class="footer__service_site">
              <div class="footer__title">
                Услуги сайта
              </div>
              <ul>
                <li>
                  <router-link to="/services/ultrasound">
                    Ультразвуковая диагностика (УЗИ)
                  </router-link>
                </li>
                <li>
                  <router-link to="/services/gynecology">
                    Гинекология
                  </router-link>
                </li>
                <li>
                  <router-link to="/services/cardiology">
                    Кардиология
                  </router-link>
                </li>
                <li>
                  <router-link to="/services/endocrinology"
                    >Эндокринология</router-link
                  >
                </li>
                <li>
                  <router-link to="/services/therapy">
                    Терапия
                  </router-link>
                </li>
                <li>
                  <router-link to="/services/gastroenterology">
                    Гастроэнтерология
                  </router-link>
                </li>
                <li>
                  <router-link to="/services/neurology">
                    Неврология
                  </router-link>
                </li>
                <li>
                  <router-link to="/services/laboratory">
                    Лабораторные исследования
                  </router-link>
                </li>
              </ul>
            </div>
            <div class="footer__contact_info">
              <div class="footer__title">
                Контактная информация
              </div>
              <div class="footer__main_tel">
                Номер регистратуры:
                <p>
                  <a :href="`tel:${main_phone_number}`">{{
                    main_phone_number
                  }}</a>
                </p>
              </div>
              <div class="footer__reg_tel">
                <p>
                  <a :href="`tel:${additional_phone_number}`">{{
                    additional_phone_number
                  }}</a>
                </p>
              </div>
              <div class="footer__address">
                Адрес:
                <p>
                  <a>
                    {{ address }}
                  </a>
                </p>
              </div>
            </div>
            <div class="footer__social_web">
              <div class="footer__title">
                Социальные сети
              </div>
              <ul>
                <li>
                  <a :href="`mailto:${email}`">{{ email }}</a>
                </li>
                <li>
                  <a target="_blank" :href="`${instagram}`">Инстаграм</a>
                </li>
                <li><a target="_blank" :href="`${vkontakte}`">Вконтакте</a></li>
              </ul>
            </div>
          </div>
          <div class="footer__bottom">
            <p>© 2019 — {{ currentYear }} МДЦ «ПРОЗДОРОВЬЕ»</p>
            <a href="https://uicode.ru/" target="_blank"
              >Разработано <span>ui</span>code</a
            >
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "footer_section",

  data() {
    return {
      currentYear: "",
    };
  },
  computed: {
    ...mapState([
      "additional_phone_number",
      "address",
      "email",
      "id",
      "instagram",
      "main_phone_number",
      "vkontakte",
      "whatsapp",
      "certificates_and_licenses",
      "privacy_policy",
    ]),
  },
  mounted() {
    this.currentYear = new Date().getFullYear();
  },
};
</script>

<style scoped></style>
