const url = '/api/';
export const api = {
    fetch : async (method, endpoint, body = null) => {
        let fetchArgs = {
            method: method,
            headers: {
                'Content-Type': 'application/json',
            },
        };
        body? fetchArgs.body = JSON.stringify(body) : null;
        return await fetch(url+endpoint, fetchArgs)
            .then(res => res.json())
    },
}
