<template>
  <div class="main">
    <director-modal :class="{ active: stateDirectorModal }"> </director-modal>
    <doctor-modal :class="{ active: stateDoctorModal }"> </doctor-modal>
    <phone-modal :class="{ active: statePhoneModal }"> </phone-modal>
    <record-modal :class="{ active: stateRecordModal }"> </record-modal>
    <review-modal :class="{ active: stateReviewModal }"> </review-modal>

    <div
      class="background__dark"
      :class="{ active: burgerState }"
      @click.self="this.$store.commit('toggleBurgerState')"
      tabindex="0"
    ></div>
    <message-modal :class="{ active: stateMessageModal }" />
    <header-section
      :address="address"
      :email="email"
      :whatsapp="whatsapp"
      :instagram="instagram"
      :vkontakte="vkontakte"
      :main_phone_number="main_phone_number"
      :additional_phone_number="additional_phone_number"
    >
    </header-section>
    <router-view
      :servicesList="servicesList"
      :address="address"
      :email="email"
      :whatsapp="whatsapp"
      :instagram="instagram"
      :vkontakte="vkontakte"
      :main_phone_number="main_phone_number"
      :additional_phone_number="additional_phone_number"
      :questions_remain="questions_remain"
      :priceList="priceList"
      v-slot="{ Component }"
    >
      <transition mode="out-in" name="fade_router">
        <component :is="Component" />
      </transition>
    </router-view>
    <footer-section
      class="footer-bottom"
      :address="address"
      :email="email"
      :whatsapp="whatsapp"
      :instagram="instagram"
      :vkontakte="vkontakte"
      :main_phone_number="main_phone_number"
      :additional_phone_number="additional_phone_number"
    >
    </footer-section>
    <div class="lodader__wrapper" v-if="isActiveLoadData">
      <div class="loader">
      </div>
      <p class="loader__text">
        Подождите...
      </p>
      <p class="loader__text">
        © 2021 ООО МДЦ «ПРОЗДОРОВЬЕ»
      </p>
    </div>
    <div class="cookie-wrapper" v-if="!cookie">

    <div class="cookie">
      <p class="">Этот сайт использует cookie-файлы и другие технологии, что помогут Вам в навигации, предоставления лучшего пользовательского опыта и анализа использование наших услуг</p>
      <button @click="cookieToggle" class="">Принять</button>
    </div>
    </div>
  </div>
</template>

<style src="./app.scss" lang="scss"></style>
<script src="./app.js"></script>
<style src="./layout/scss/style.scss" lang="scss"></style>
